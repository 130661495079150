<template>
  <b-card v-if="materialRecepcionData">
    <!-- Form -->
    <b-overlay :show="loading">
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row align-h="start" class="mx-0">
            <h3 class="text-primary">Recepción de materiales</h3>
          </b-row>
          <b-row align-v="center">
            <b-col cols="12" lg="4" md="4" sm="6">
              <!-- Nº Order-->
              <validation-provider #default="validationContext" name="Nº Order">
                <b-form-group
                  label="Nº Order"
                  label-for="proveedor"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-input
                    :disabled="!canEdit"
                    v-model="materialRecepcionData.num_pedido_proveedor"
                    placeholder="PPXX-YY-MM"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- FK Proveedor -->
            <b-col cols="12" lg="5" md="5" sm="6">
              <validation-provider
                #default="validationContext"
                name="proveedor"
                rules="required"
              >
                <b-form-group
                  label="Proveedor*"
                  label-for="proveedor"
                  :state="getValidationState(validationContext)"
                >
                  <v-select
                    v-model="materialRecepcionData.fk_proveedor"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proveedores"
                    :reduce="(val) => val.id"
                    :clearable="false"
                    input-id="proveedor"
                    placeholder="Seleccione"
                    label="nombre_comercial"
                    :disabled="!canEdit"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Fecha -->
            <b-col>
              <validation-provider #default="validationContext" name="Fecha">
                <b-form-group
                  label="Fecha*"
                  label-for="fecha"
                  :state="getValidationState(validationContext)"
                >
                  <flat-pickr
                    v-model="materialRecepcionData.fecha_recepcion"
                    placeholder="dd-mm-yyyy"
                    class="form-control"
                    :config="{
                      minDate: 'today',
                      format: 'd-m-Y',
                      altFormat: 'd-m-Y',
                      allowInput: true,
                      altInput: true,
                      locale: Spanish
                    }"
                    :disabled="!canEdit"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <hr style="border-top: 1px solid gainsboro" />

          <h3 class="mb-1">Materiales</h3>
          <b-table
            :items="recepcionItems"
            responsive
            striped
            :fields="tableHeaders"
          >
            <!-- Ref Prod -->
            <template #cell(refProd)="data">
              <validation-provider
                #default="validationContext"
                name="ref-prod"
                rules="required"
              >
                <b-form-group :state="getValidationState(validationContext)">
                  <v-select
                    v-model="data.item.refProd"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="materiales"
                    label="codigo"
                    :clearable="false"
                    placeholder="Select Item"
                    @input="(val) => updateItemForm(data.index, val)"
                    append-to-body
                    style="min-width: 200px"
                    :disabled="!canEdit"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- Descripcion -->
            <template #cell(descripcion)="data">
              <validation-provider
                #default="validationContext"
                name="Descripcion-Material"
                rules="required"
              >
                <b-form-group :state="getValidationState(validationContext)">
                  <v-select
                    v-model="data.item.descripcion"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="materiales"
                    label="descripcion"
                    :clearable="false"
                    placeholder="Select Item"
                    :disabled="!canEdit"
                    @input="(val) => updateItemForm(data.index, val)"
                    append-to-body
                    style="min-width: 200px"
                  />

                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- Coste -->
            <template #cell(coste)="data">
              <validation-provider
                #default="validationContext"
                name="Coste"
                rules="required|min_value:1"
              >
                <b-form-group :state="getValidationState(validationContext)">
                  <b-form-input
                    v-model="data.item.coste"
                    type="number"
                    step="0.01"
                    :disabled="!canEdit"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- Cantidad -->
            <template #cell(cantidad)="data">
              <validation-provider
                #default="validationContext"
                name="Cantidad"
                rules="required|min_value:1"
              >
                <b-form-group :state="getValidationState(validationContext)">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    :disabled="!canEdit"
                  />
                  <b-form-invalid-feedback
                    :state="getValidationState(validationContext)"
                  >
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </template>

            <!-- Column: Actions -->
            <template #cell(actions)="data">
              <!-- Eliminar -->
              <b-button
                v-if="canEdit"
                :id="`delete-${data.item.id}`"
                @click="removeItem(data.index, data.item)"
                variant="outline-danger"
                class="btn-icon mb-1"
              >
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>

          <b-row class="px-0" v-if="canEdit">
            <!-- Items Section -->
            <b-card-body class="form-item-section">
              <b-button
                size="sm"
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Añadir Material
              </b-button>
            </b-card-body>
          </b-row>

          <b-row class="mt-2 mx-0" align-h="end" v-if="canEdit">
            <b-button
              variant="outline-secondary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'materiales-recepcion-list' }"
            >
              Cancelar
            </b-button>
            <b-button
              variant="primary"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
              :disabled="invalid"
            >
              Guardar stocks
            </b-button>
          </b-row>
          <b-row class="mt-2 mx-0" align-h="end" v-else>
            <b-button
              variant="outline-primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'materiales-recepcion-list' }"
            >
              Lista de Recepcións
            </b-button>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormInvalidFeedback,
  BFormTextarea,
  BOverlay,
  BModal,
  BInputGroupAppend,
  BInputGroup,
  BInputGroupPrepend,
  BTable,
  BCardBody,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, onUnmounted } from "@vue/composition-api";

import { Spanish } from 'flatpickr/dist/l10n/es'
import { required } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import router from "@/router";
import store from "@/store";
import materialesRecepcionStoreModule from "./materialesRecepcionStoreModule";
import materialesStoreModule from "@/views/almacen/materiales/materialesStoreModule";
import useMaterialesList from "@/views/almacen/materiales/useMaterialesList";
import vSelect from "vue-select";
import MaterialesRecepcion from "@/models/MaterialesRecepcion";
import useProveedoresList from "@/views/system/proveedor/useProveedoresList";
import proveedorStoreModule from "@/views/system/proveedor/proveedorStoreModule";
import flatPickr from "vue-flatpickr-component";
import MaterialesRecepcionDetalles from "@/models/MaterialesRecepcionDetalles";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    BOverlay,
    BModal,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupPrepend,
    BTable,
    BCardBody,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    flatPickr,
  },

  data() {
    return {
      Spanish,
      required,
    };
  },

  methods: {
    onSubmit() {
      if (this.$route.params.id === "new")
        this.materialRecepcionData.fk_user = this.user.id;

      this.materialRecepcionData.detalles = [];
      this.recepcionItems.forEach((el) => {
        this.materialRecepcionData.detalles.push(
          new MaterialesRecepcionDetalles({
            fk_material: el.id,
            coste: el.coste,
            cantidad: el.cantidad,
          })
        );
      });

      this.$store
        .dispatch(
          "app-materiales-recepcion/addMaterialRecepcion",
          this.materialRecepcionData
        )
        .then(() => {
          this.$router.push({ name: "materiales-recepcion-list" });
        });
    },
    addNewItemInItemForm() {
      this.recepcionItems.push(
        JSON.parse(JSON.stringify(this.itemFormBlankItem))
      );
    },
    removeItem(index, item) {
      this.recepcionItems.splice(index, 1);
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    loading() {
      return this.$store.state["app-materiales-recepcion"].loading;
    },
    canEdit() {
      return this.$route.params.id !== "new" ? false : true;
    },
  },

  setup() {
    const blankmaterialRecepcionData = new MaterialesRecepcion({
      fecha_recepcion: new Date().toISOString().split("T")[0],
    });
    const materialRecepcionData = ref(null);
    const proveedores = ref([]);
    const recepcionItems = ref([]);

    const MATERIALES_RECEPCION_STORE_MODULE_NAME = "app-materiales-recepcion";
    const PROVEEDOR_APP_STORE_MODULE_NAME = "app-proveedor";
    const MATERIALES_APP_STORE_MODULE_NAME = "app-materiales";

    const resetmaterialRecepcionData = () => {
      materialRecepcionData.value = JSON.parse(
        JSON.stringify(blankmaterialRecepcionData)
      );
    };

    // Register module
    if (!store.hasModule(MATERIALES_RECEPCION_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_RECEPCION_STORE_MODULE_NAME,
        materialesRecepcionStoreModule
      );
    }
    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule
      );
    }
    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MATERIALES_RECEPCION_STORE_MODULE_NAME))
        store.unregisterModule(MATERIALES_RECEPCION_STORE_MODULE_NAME);
      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME);
      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME))
        store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME);
    });

    const materiales = ref(null);
    const { listMateriales } = useMaterialesList();
    listMateriales().then((response) => {
      materiales.value = response;
    });

    const updateItemForm = (index, val) => {
      const { codigo, id, cantidad, coste, descripcion } = val;
      recepcionItems.value[index].refProd = codigo;
      recepcionItems.value[index].id = id;
      recepcionItems.value[index].coste = coste;
      recepcionItems.value[index].cantidad = cantidad ?? 0;
      recepcionItems.value[index].descripcion = descripcion
    };

    const itemFormBlankItem = ref({
      id: "",
      refProd: "",
      descripcion: "",
      cantidad: 0,
      coste: 0,
    });

    const { listProveedores } = useProveedoresList();

    listProveedores().then((response) => {
      proveedores.value = response;
    });

    if (router.currentRoute.params.id !== "new") {
      store
        .dispatch(
          "app-materiales-recepcion/fetchMaterialRecepcion",
          router.currentRoute.params.id
        )
        .then(async (response) => {

          materialRecepcionData.value = response;

          const detalles = materialRecepcionData.value.detalles;

          for (var i = 0; i < detalles.length; i++) {
            let material = await store.dispatch(
              "app-materiales/fetchMaterial",
              detalles[i].fk_material
            );

            recepcionItems.value.push({
              id: material.id,
              refProd: material.codigo,
              cantidad: detalles[i].cantidad,
              coste: detalles[i].coste,
              descripcion: material.descripcion
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 404) {
            materialRecepcionData.value = undefined;
          }
        });
    } else {
      materialRecepcionData.value = blankmaterialRecepcionData;
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetmaterialRecepcionData
    );

    const tableHeaders = ref([
      { key: "refProd", label: "REF PROD", sortable: false },
      { key: "descripcion", label: "Descripcion", sortable: false },
      { key: "coste", label: "Coste Unit. (€)" },
      { key: "cantidad", label: "Cantidad", sortable: false },
      { key: "actions", label: "", thStyle: { minWidth: "120px" } },
    ]);

    return {
      materialRecepcionData,
      refFormObserver,
      getValidationState,
      resetForm,

      proveedores,
      recepcionItems,
      updateItemForm,
      itemFormBlankItem,
      tableHeaders,
      materiales,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
